import { thunk, useStoreState } from 'easy-peasy';
import pick from 'lodash.pick';
import { ethers } from 'ethers';

import { ErrorTypes, ProjectStatus } from 'constants/index';
import {
  AdminProjectsActionsAndThunks,
  IAdminProjectsThunks,
  IApproveProjectPayload,
  IDeleteProjectPayload,
  IFilterRequestParams,
  IMarkProjectForDeletion,
  IRejectProjectPayload,
  IStoreModel,
  TradeStatus,
} from 'types';
import adminProjectsAPI from 'api/admin/adminProjectsAPI';
import { AuctionContract, PlatformContract } from 'blockchain';
import { TransactionReceiptStatus } from 'constants/transaction';
import tradingAPI from 'api/trading/tradingAPI';
import { convertUnixTimestampToMiliseconds } from 'utils/convertUnixTimestamp';
import moment from 'moment';
import { TradingContract } from 'blockchain/contracts/TradingContract';
import { verifyTransaction } from 'utils/verifyTransaction';
import { notification } from 'antd';
import { validateWalletForTransaction } from 'utils/validateTransaction';

export const thunks: IAdminProjectsThunks = {
  loadPendingProjects: thunk<AdminProjectsActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.startPendingProjectsLoading();
      try {
        const pendingProjects = await adminProjectsAPI.getPendingProjects(
          pick(payload, ['name', 'status', 'page', 'limit', 'createdAt']),
        );
        actions.setPendingProjects(pendingProjects);
      } finally {
        actions.stopPendingProjectsLoading();
      }
    },
  ),

  loadProjectById: thunk<AdminProjectsActionsAndThunks, string | number, unknown, IStoreModel>(
    async (actions, payload) => {
      const project = await adminProjectsAPI.getProjectById(payload);
      actions.setProject(project);
    },
  ),

  startProjectReview: thunk<AdminProjectsActionsAndThunks, string, unknown, IStoreModel>(async (actions, payload) => {
    await adminProjectsAPI.updateProject(payload, { status: ProjectStatus.InReview });
  }),

  approveProject: thunk<AdminProjectsActionsAndThunks, IApproveProjectPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;
      if (!payload.project.tokenAddr) {
        if (!wallet.provider) {
          notification.error({
            message: 'No provider',
          });
          return;
        }

        const signer = await validateWalletForTransaction({ web3Provider: wallet.provider });

        if (!signer) {
          console.error('No valid signer available');
          return;
        }

        const contract = new PlatformContract(signer);
        console.log('Registering project');
        const tx = await contract.registerProject(payload.project);

        const receipt = await tx.wait();

        if (receipt.status === TransactionReceiptStatus.Success) {
          await adminProjectsAPI.updateProject(payload.project.id, {
            status: ProjectStatus.Approved,
            txHash: tx.hash,
          });
        }
      } else {
        await adminProjectsAPI.updateProject(payload.project.id, {
          status: ProjectStatus.Approved,
        });
      }
    },
  ),

  rejectProject: thunk<AdminProjectsActionsAndThunks, IRejectProjectPayload, unknown, IStoreModel>(
    async (actions, payload) => {
      await adminProjectsAPI.updateProject(payload.id, {
        status: ProjectStatus.Rejected,
        reason: payload.reason,
      });
    },
  ),

  deleteProject: thunk<AdminProjectsActionsAndThunks, IDeleteProjectPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        notification.error({
          message: 'No provider',
        });
        return;
      }

      const signer = await validateWalletForTransaction({ web3Provider: wallet.provider });

      if (!signer) {
        console.error('No valid signer available');
        return;
      }
      const auctionContract = new AuctionContract(signer);

      const auction = await AuctionContract.getAuction(payload.accountAddress);
      const auctionStartTime = +ethers.BigNumber.from(auction.startTime).toString();

      // check if project has active auction
      if (
        auction.isValid &&
        auctionStartTime &&
        convertUnixTimestampToMiliseconds(Number(auctionStartTime)) > moment().valueOf()
      ) {
        const isSuccess = await verifyTransaction(auctionContract.cancelAuction(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.DeleteAuction);
        }
      }

      const tradings = await tradingAPI.getList({
        userId: payload.userId,
        status: TradeStatus.Active,
      });

      const tradingItems = tradings.items;

      const tradingContract = new TradingContract(signer);

      if (tradingItems.length > 0) {
        const isSuccess = await verifyTransaction(
          tradingContract.batchDeleteSellBuy(Array.from(tradingItems, (item) => item.offerId)),
        );

        if (!isSuccess) {
          throw new Error(ErrorTypes.DeleteBids);
        }
      }

      const contract = new PlatformContract(signer);

      const projectInfo = await contract.printProjectInfo(payload.accountAddress);

      if (projectInfo?.length) {
        const isSuccess = await verifyTransaction(contract.removeProject(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.DeleteProject);
        }
      }

      await adminProjectsAPI.deleteProject(payload.id);
    },
  ),

  markForDeletion: thunk<AdminProjectsActionsAndThunks, IMarkProjectForDeletion, unknown, IStoreModel>(
    async (actions, payload) => {
      await adminProjectsAPI.markForDeletion(payload.id, {
        markedForDeletion: payload.markedForDeletion,
      });
    },
  ),
};
