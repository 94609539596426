import { thunk } from 'easy-peasy';
import pick from 'lodash.pick';

import {
  IFilterRequestParams,
  AdminUsersActionsAndThunks,
  IStoreModel,
  IAdminUsersThunks,
  IRejectUserPayload,
  IAdminUserResponseBody,
  IDeleteAdminUserPayload,
  TradeStatus,
  ITrading,
} from 'types';
import adminUsersAPI from 'api/admin/adminUsersAPI';
import { ErrorTypes, UserRole } from 'constants/index';
import { AuctionContract, PlatformContract } from 'blockchain';
import { TradingContract } from 'blockchain/contracts/TradingContract';
import tradingAPI from 'api/trading/tradingAPI';
import { verifyTransaction } from 'utils/verifyTransaction';
import { notification } from 'antd';
import { validateWalletForTransaction } from 'utils/validateTransaction';

export const thunks: IAdminUsersThunks = {
  loadAdminUsers: thunk<AdminUsersActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.startUsersLoading();
      try {
        const users = await adminUsersAPI.getUsers({
          ...pick(payload, ['name', 'status', 'page', 'limit', 'createdAt']),
          role: UserRole.Admin,
        });
        actions.setUsers(users);
      } finally {
        actions.stopUsersLoading();
      }
    },
  ),

  approveUser: thunk<AdminUsersActionsAndThunks, IAdminUserResponseBody, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        notification.error({
          message: 'No provider',
        });
        return;
      }

      const signer = await validateWalletForTransaction({ web3Provider: wallet.provider });

      if (!signer) {
        console.error('No valid signer available');
        return;
      }
      const platformContract = new PlatformContract(signer);
      const auctionContract = new AuctionContract(signer);
      const tradingContract = new TradingContract(signer);

      const hasSuperAdminRole = await platformContract.hasSuperAdminRole(wallet.walletAddress!);

      if (!hasSuperAdminRole) {
        throw new Error(ErrorTypes.AdminPermissionError);
      }

      const hasAdminRole = await platformContract.hasAdminRole(payload.accountAddress);

      if (!hasAdminRole) {
        const isSuccess = await verifyTransaction(platformContract.registerAdmin(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.RegisterCompany);
        }
      }

      const hasAuctionOperatorRole = await auctionContract.hasOperatorRole(payload.accountAddress);

      if (!hasAuctionOperatorRole) {
        const isSuccess = await verifyTransaction(auctionContract.grantOperatorRole(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.GrantRole);
        }
      }

      const hasTradingOperatorRole = await tradingContract.hasOperatorRole(payload.accountAddress);

      if (!hasTradingOperatorRole) {
        const isSuccess = await verifyTransaction(tradingContract.grantOperatorRole(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.GrantRole);
        }
      }

      return await adminUsersAPI.approveAdminUser(payload.id);
    },
  ),

  rejectUser: thunk<AdminUsersActionsAndThunks, IRejectUserPayload, unknown, IStoreModel>(async (actions, payload) => {
    await adminUsersAPI.rejectUser(payload.id);
  }),

  deleteAdminUser: thunk<AdminUsersActionsAndThunks, IDeleteAdminUserPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        notification.error({
          message: 'No provider',
        });
        return;
      }

      const signer = await validateWalletForTransaction({ web3Provider: wallet.provider });

      if (!signer) {
        console.error('No valid signer available');
        return;
      }
      const contract = new PlatformContract(signer);

      const hasSuperAdminRole = await contract.hasSuperAdminRole(wallet.walletAddress!);

      if (!hasSuperAdminRole) {
        throw new Error(ErrorTypes.AdminPermissionError);
      }

      const hasAdminRole = await contract.hasAdminRole(payload.accountAddress);

      if (hasAdminRole) {
        await contract.revokeAdminRole(payload.accountAddress);
      }

      const auctionContract = new AuctionContract(signer);

      const hasAuctionOperatorRole = await auctionContract.hasOperatorRole(payload.accountAddress);

      if (hasAuctionOperatorRole) {
        const isSuccess = await verifyTransaction(auctionContract.revokeOperatorRole(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.RevokeRole);
        }
      }

      const tradingContract = new TradingContract(signer);

      const hasTradingOperatorRole = await tradingContract.hasOperatorRole(payload.accountAddress);

      if (hasTradingOperatorRole) {
        const isSuccess = await verifyTransaction(tradingContract.revokeOperatorRole(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.RevokeRole);
        }
      }

      const tradings = await tradingAPI.getList({
        userId: payload.userId,
        status: TradeStatus.Active,
      });

      const tradingItems: ITrading[] = tradings.items;

      if (tradingItems.length > 0) {
        await tradingContract.batchDeleteSellBuy(Array.from(tradings.items, (item) => item.offerId));
      }

      return await adminUsersAPI.deleteAdminUser(payload.id);
    },
  ),
};
