import { ethers } from 'ethers';
import { Notification } from 'components';
import { NotificationTypes } from 'constants/notificationTypes';

const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
const MIN_BALANCE = ethers.utils.parseEther('0.01');
const SUPPORTED_CHAINS = [137, 80001, CHAIN_ID];

interface ValidateWalletParams {
  web3Provider: ethers.providers.Web3Provider | null;
}

export const validateWalletForTransaction = async ({
  web3Provider,
}: ValidateWalletParams): Promise<ethers.providers.JsonRpcSigner | undefined> => {
  if (!web3Provider) {
    Notification({
      notificationType: NotificationTypes.Error,
      message: 'Wallet Not Connected',
      description: 'Please connect your wallet to continue.',
    });
    return;
  }

  const accounts = await web3Provider.send('eth_accounts', []);
  if (accounts.length === 0) {
    Notification({
      notificationType: NotificationTypes.Error,
      message: 'Wallet Not Connected',
      description: 'Please connect your wallet to continue.',
    });
    return;
  }

  const address = accounts[0];
  const balance = await web3Provider.getBalance(address);
  if (balance.lt(MIN_BALANCE)) {
    Notification({
      notificationType: NotificationTypes.Error,
      message: 'Insufficient Balance',
      description: 'Your wallet does not have enough funds to proceed.',
    });
    return;
  }

  const network = await web3Provider.getNetwork();
  if (!SUPPORTED_CHAINS.includes(network.chainId)) {
    Notification({
      notificationType: NotificationTypes.Error,
      message: 'Unsupported Network',
      description: `The current network (Chain ID: ${network.chainId}) is not supported.`,
    });
    return;
  }

  return web3Provider.getSigner();
};
