import { ChangeEvent, memo } from 'react';
import cn from 'classnames';
import { Form, Input, InputProps } from 'antd';
import { IBasicInputProps } from 'types';
import { FieldAttributes } from 'formik';

import styles from './TextInput.module.scss';

interface IProps extends IBasicInputProps<string>, Omit<InputProps, 'name' | 'onChange' | 'onBlur'> {
  help?: string;
  field?: FieldAttributes<any>;
  fieldlabel?: string;
}

const TextInput = ({
  name,
  label,
  error,
  onChange,
  icon,
  fieldlabel,
  field,
  className = '',
  size = 'middle',
  help,
  ...rest
}: IProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
    field?.onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field?.onBlur(e);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      help={error || help}
      validateStatus={error ? 'error' : ''}
      className={styles.container}
    >
      {fieldlabel && (field.value || false) && <div className={styles.fieldLabel}>{fieldlabel}</div>}
      <Input
        {...rest}
        {...field}
        size={size}
        className={cn(
          styles.input,
          styles[size],
          className,
          {
            [styles.icon]: icon,
          },
          {
            [styles.focusedField]: (field?.value || false) && fieldlabel,
          },
        )}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};

export default memo(TextInput);
